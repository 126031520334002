div[role=main] {
  margin-top: 2em;
}
.main-login-container {
  margin-top: 5em;
  min-width: 10em;
  max-width: 25em;
}

#root {
  padding-bottom: 20px;
}
